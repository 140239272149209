import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { ExternalNavBarDesktop_UserFragment } from 'generated/graphql'
import AppInstallQRCode from '@app.components/AppInstallQRCode/AppInstallQRCode'
import UserAction from '../UserAction/UserAction'

gql`
  fragment ExternalNavBarDesktop_user on User {
    ...UserAction_user
  }
`

interface IFExternalNavBarDesktopProps {
  currentUser?: ExternalNavBarDesktop_UserFragment | null
  loading?: boolean
}

const ExternalNavBarDesktop: FC<IFExternalNavBarDesktopProps> = ({
  currentUser,
  loading,
}) => {
  return (
    <StyledWrapper>
      <div className="external-nav-container">
        <AppInstallQRCode />
        <UserAction currentUser={currentUser} loading={loading} />
      </div>
    </StyledWrapper>
  )
}

export default ExternalNavBarDesktop

const StyledWrapper = styled.div`
  border-bottom: 1px solid #ebebeb;

  .external-nav-container {
    width: 100%;
    max-width: 1148px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 24px;
  }
`
