import dynamic from 'next/dynamic'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import {
  HeaderNavItemType,
  NavBarActiveKey,
  NavBarBadge,
} from 'app.layout/constant/pageLayout.type'
import { useIsStucked } from 'hooks/useGnbStucked'
import CoverLetterCount from './CoverLetterCount'
import NewBadge from './NewBadge'

interface NavMenuItemProps {
  menuInfo: HeaderNavItemType
  activeKey: NavBarActiveKey
  isShowNavbarDetail: boolean
}

const NavBarHoverMenu = dynamic(() => import('./NavBarHoverMenu'), {
  ssr: false,
})

const NavMenuItem = ({
  menuInfo,
  activeKey,
  isShowNavbarDetail,
}: NavMenuItemProps) => {
  const { isStucked, navbarRef } = useIsStucked<HTMLLIElement>()

  const getIsSelectedMainMenu = () => {
    return Boolean(
      menuInfo.menuItems?.find((item) => {
        return item.active === activeKey
      }),
    )
  }

  return (
    <StyledWrapper key={menuInfo.label} ref={navbarRef}>
      <Link href={menuInfo.href}>
        <a className="menu-button" data-active={getIsSelectedMainMenu()}>
          {menuInfo.label}
          {menuInfo.badge === NavBarBadge.NEW && <NewBadge />}
          {menuInfo.badge === NavBarBadge.COUNT && (
            <>{!isStucked ? <CoverLetterCount /> : <NewBadge />}</>
          )}
        </a>
      </Link>
      {menuInfo.menuItems?.length && !isShowNavbarDetail && (
        <NavBarHoverMenu
          activeKey={activeKey}
          menuItems={menuInfo.menuItems}
          isSelectedMainMenu={getIsSelectedMainMenu()}
        />
      )}
    </StyledWrapper>
  )
}

export default NavMenuItem

const StyledWrapper = styled.li`
  ${({ theme }) => css`
    display: block;
    height: 100%;

    .menu-button {
      font-size: 17px;
      font-weight: bold;
      line-height: 25px;
      letter-spacing: -0.68px;
      text-align: left;
      color: ${theme.color.text_1};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 60px;

      &[data-active='true'] {
        color: ${theme.color.primary_2};
        font-weight: bold;
      }

      &:hover {
        color: ${theme.color.primary_2};
        background-color: transparent;
        font-weight: bold;
      }
    }

    .root-dropdown {
      display: none;
      z-index: 11;
    }

    .second-depth-nav-bar-hover {
      z-index: 10;
      display: none;
    }

    &:hover {
      .root-dropdown {
        display: block;
      }

      .second-depth-nav-bar-hover {
        display: block;
        z-index: 11;
      }
    }
  `}
`
